<template>
  <b-row no-gutters>
    <b-col v-if="timetables.length > 0" class="">
      {{ labels }}
    </b-col>
    <b-col v-else>
      {{ $t('components.custom.table.no-available-timetable') }}
    </b-col>
  </b-row>
</template>
<script>

export default {
  props: {
    timetables: {
      type: Array,
      default: [],
    }
  },
  data: () => ({
    formattedTimetables: '',
  }),
  computed: {
    labels () {
      return this.formattedTimetables;
    }
  },
  mounted () {
    this.formattedDays = '';

    for (const i in this.timetables) {
      this.formattedTimetables += parseInt(i) === 0 ? this.timetables[i].name : ', ' + this.timetables[i].name;
    }
  }
}
</script>